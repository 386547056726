import { Link } from "gatsby";
import React, { useState, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import PlayVideo from "../PlayVideo/PlayVideo";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import NoImage from "../../images/property-eight.png"
import GetGGFXImage from "../common/site/get-ggfx-image";
import "./PropertyBanner.scss";
import $ from "jquery";
import Slider from "react-slick";
import { BsChevronRight as IconRight, BsChevronLeft as IconLeft } from "react-icons/bs";
import { isMobile } from "react-device-detect";
const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"

// markup
const OffPlanPropertyBanner = (props) => { 
  let processedImages = JSON.stringify({});
  if (props?.propertyData?.imagetransforms?.images_Transforms) {
    processedImages = props?.propertyData?.imagetransforms.images_Transforms;
  }
  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isPlay, setPlay] = useState(false);
  const [videourl, setVideourl] = useState(false);
  const [videotour, setVideoTour] = useState(false);

  const openPropertyImage = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }
  // Property images lightbox

  const propertyImages = props?.propertyData?.images
  var propertyLightImages = propertyImages && propertyImages.map(img => img.url);
  var lightboxresize_images = props?.propertyData?.imagetransforms ? JSON.parse(props?.propertyData?.imagetransforms?.images_Transforms) : '';

  let PropertyImagesForLightBox = [];
  propertyImages && propertyImages.map(img => {
    if (img.url) {
      const SrcCftle = img.url.substring(img.url.indexOf(STAGE_ENV));
      const ProcessedUrl = lightboxresize_images[SrcCftle] && lightboxresize_images[SrcCftle]?.webp ? lightboxresize_images[SrcCftle]?.webp['1600x900'] : img.url;
      //console.log('ProcessedUrl', ProcessedUrl)
      if (ProcessedUrl)
        PropertyImagesForLightBox.push(ProcessedUrl);
    }
  });

  if (!isMobile) {
    PropertyImagesForLightBox = propertyLightImages
  }
 

  // Property details images lightbox  
  function gotohash(event) {
    var thishash = event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top - 150
    }, 1000);
  }
  //console.log("imagetransformsimagetransforms", props?.propertyData?.images)

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style }}
        onClick={onClick}
      ><IconRight /></button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style }}
        onClick={onClick}
      ><IconLeft /></button>
    );
  }

 

  return (
    <Fragment>
      <section className="properties-banner">

        <div className="banner-img-wrapper">
          {
              props.propertyData?.images.length > 0 ?
              <GetGGFXImage imagename={"offplan.images.bannerImg"} imagesources={props.propertyData?.images[0]} fallbackalt={props?.propertyData?.display_address + ' - Arabian Estates Properties'} imagetransformresult={processedImages} id={props?.propertyData?.id} />
              :
              <img src={NoImage} />
          }
        </div>
      {/* <Container>
          <Row>
            <Col md={12}>

        <div className="property-banner">
          <div className="banner-left zoom">
            <div className="property-img">
            
              {isMobile && props.propertyData?.images?.length > 0 &&
                <Slider {...settings}>
                  {props.propertyData.images.map((image, index) => (
                    <div className="brands" key={index + "propertyImage"}>
                      <a href="javascript:void(0)" className="zoom" onClick={(e) => openPropertyImage(e, index)}>
                        <GetGGFXImage imagename={"offplan.images.detail"} imagesources={image} fallbackalt={props?.propertyData?.display_address + ' - Arabian Estates Properties'} imagetransformresult={processedImages} id={props?.propertyData?.id} />
                      </a>
                    </div>
                  ))}
                </Slider>}
              {!isMobile &&
                <picture>
                  {props.propertyData && props.propertyData.images[0] &&
                    <a href="javascript:void(0)" className="zoom" onClick={(e) => openPropertyImage(e, 0)}>
                      <GetGGFXImage imagename={"offplan.images.detail"} imagesources={props.propertyData.images[0] && props.propertyData.images[0]} fallbackalt={props?.propertyData?.display_address} imagetransformresult={processedImages} id={props?.propertyData?.id} />
                    
                    </a>
                  }
                </picture>
              }
              {!props.propertyData && !props.propertyData.images[0] &&
                <img src={NoImage} alt={props?.propertyData?.display_address + ' - Arabian Estates Properties'} />}

            </div>
          </div>
          <div className="banner-right d-none d-lg-flex">
                  <div className="banner-right-top zoom">
                    <picture>
                      {props.propertyData && props.propertyData.images[1] ?
                        <a href="javascript:void(0)" className="zoom" onClick={(e) => openPropertyImage(e, 0)}>
                          <GetGGFXImage imagename={"offplan.images.detail"} imagesources={props.propertyData.images[1] && props.propertyData.images[1]} fallbackalt={props?.propertyData?.display_address} imagetransformresult={processedImages} id={props?.propertyData?.id} />
                        </a>
                        :
                        <img src={NoImage} alt={props?.propertyData?.display_address + ' - Arabian Estates Properties'} />
                      }
                    </picture>

                    {props.propertyData && props.propertyData.virtual_tour?.length > 0 && props.propertyData.virtual_tour.map((item, index) => {
                      if (item.video_type == "3d tour" || item.video_type == "3D Tour" || item.video_type == "360 tour") {
                        return (
                          <div className="property-icons">
                            <a onClick={(e) => { setPlay(true); setVideoTour(item.url); setVideourl(false); }} className="d-none d-lg-flex">
                              <i className="icon-tour"></i>
                              View 360 tour
                            </a>
                          </div>
                        )
                      }
                    })}
                  </div>
                  <div className="banner-right-bottom zoom">
                    <picture>
                      {props.propertyData && props.propertyData.images[2] ?
                        <a href="javascript:void(0)" className="zoom" onClick={(e) => openPropertyImage(e, 0)}>
                          <GetGGFXImage imagename={"offplan.images.detail"} imagesources={props.propertyData.images[2] && props.propertyData.images[2]} fallbackalt={props?.propertyData?.display_address} imagetransformresult={processedImages} id={props?.propertyData?.id} />
                        </a>
                        :
                        <img src={NoImage} alt={props?.propertyData?.display_address + ' - Arabian Estates Properties'} />
                      }
                    </picture>
                    {props.propertyData && props.propertyData.virtual_tour?.length > 0 && props.propertyData.virtual_tour.map((item, index) => {
                      if (item.video_type == "video tour") {
                        return (
                          <div className="property-icons">
                            <a onClick={(e) => { setPlay(true); setVideourl(item.url); setVideoTour(false); }} className="d-none d-lg-flex">
                              <i className="icon-video-camera"></i>
                              Watch Video Tour
                            </a>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
        </div>
        </Col>
          </Row>
        </Container> */}

      </section> 
      {isOpen && (
        <Lightbox
          mainSrc={PropertyImagesForLightBox[photoIndex]}
          nextSrc={PropertyImagesForLightBox[(photoIndex + 1) % PropertyImagesForLightBox.length]}
          prevSrc={PropertyImagesForLightBox[(photoIndex + PropertyImagesForLightBox.length - 1) % PropertyImagesForLightBox.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + PropertyImagesForLightBox.length - 1) % PropertyImagesForLightBox.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % PropertyImagesForLightBox.length)
          }
        />
      )}
    </Fragment>
  );
};
export default OffPlanPropertyBanner;
