import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import "./PropertySlider.scss"

const PropertySliderOffPlan = props => {
  let bedlist = [1,2,3,4,5,6,7,8,9,10]
  return (
    <div className="property-card off-plan-card">
      <Link to={props.propertyDetailsLink}>
        <div className="property-img">
          {props.propertyImages && props.propertyImages.length > 0 ? (
            <img src={props.propertyImages[0].url} alt={props.location} />
          ) : (
            <img src={props.thumb} alt="card-img" />
          )}
        </div>
      </Link>
      <div className="property-content">
        <Link to={props.propertyDetailsLink}>
        <h4>{props?.extra?.off_Plan_Project_Building_Name}</h4>
              <h3>{props?.location}</h3>
        </Link>
        {props.price ? (
          <>
             <p className="price"><b>Prices from :</b> {`AED ${props.price} `}
                {/* ${props.max_price ? `- AED ${props.max_price}` : ""} */}
                </p>
            <div className="_divider"></div>
          </>
        ) : (
          ""
        )}
        <p> {bedlist?.map((item, i) => {
                                    return(
                                        <>
                                        {props.extra?.off_Plan_Minimum_Bedrooms <= item && props.extra?.off_Plan_Maximum_Bedrooms >= item ? 
                                    <>{props.extra?.off_Plan_Maximum_Bedrooms === item ? `&  ${item} ` : `${item} `}</>    
                                    : null}
                                        </>
                                    )
                                })}
                                 Bedroom Apartments</p>
        {props?.completion_date && (
          <>
            <div className="_divider"></div>
            <p>
              Completion Date:{" "}
              {moment(props?.completion_date).format("MMMM YYYY")}
            </p>
            <div className="_divider"></div>
          </>
        )}

        <div className="view_section">
          <Link to={props.propertyDetailsLink} className="view-details">
            View Details
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PropertySliderOffPlan
