import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactDetails from '../ContactDetails/ContactDetails'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import PaymentCard from '../PaymentCard/PaymentCard'
import PlanOverview from '../PlanOverview/PlanOverview'
import ProjectVideo from '../ProjectVideo/ProjectVideo'
import cardImg from "../../images/id-img.png"
import './PlanData.scss'
import { Link } from 'gatsby'
const PlanData = () => {
  const contactData = {
    cardHeading: 'Contact Agent',
    teleHeading: 'Vikram Biant',
    image: cardImg,
    telephone: '+971 50 696 4079',
    btnView: "Book a Viewing",
    btnText: "Register your interest"
  }
  const paymentPlanData = [{
    installation: "Down Payment — 10%",
    installationInfo: "On booking"
  },
  {
    installation: "1st Instalment — 10%",
    installationInfo: "Within 2 months from booking date"
  },
  {
    installation: "2nd Instalment — 10%",
    installationInfo: "Within 6 months from 1st Instalment date"
  },
  {
    installation: "3nd Instalment — 10%",
    installationInfo: "On 10% Construction completion*"
  },
  {
    installation: "4th Instalment — 20%",
    installationInfo: "On 70% Construction completion*"
  },
  {
    installation: "5th Instalment — 40%",
    installationInfo: "On completion | 100% Construction completion*"
  }
  ]
  const navLists = [
    {
      name: "overview",
      slug: "overview"
    },
    {
      name: "gallery",
      slug: "gallery"
    },
    {
      name: "video",
      slug: "video"
    },
    {
      name: "payment plan",
      slug: "payment-plan"
    },
    {
      name: "location",
      slug: "location"
    },
    {
      name: "siteplan",
      slug: "siteplan"
    }
  ]

  return (
    <section className='plan-data'>
      <div className='page-nav'>
        <Container>
          <Row>
            <Col>
              <ul>
                {
                  navLists.map((item, index) => {
                    return <li className={!index && "active"}>
                      <Link to='/' className="nav-links" key={index}>{item.name}</Link>
                    </li>
                  })
                }
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='overview-wrapper'>
        <Container>
          <Row>
            <Col>
              <div className='d-xl-flex justify-content-between'>
                <div className='overview-ltl'>
                  <AnimationOnScroll animateIn="animate__fadeInLeft">
                    <PlanOverview />
                    <ProjectVideo />
                    <div className='payment-wrappr' id="payment-plan">
                      <h4>Payment Plan</h4>
                      <p>Contact us to know more about our attractive payment plans and choose the one that best fits your needs. *Estimated construction completion date.</p>
                      <PaymentCard paymentPlanData={paymentPlanData} />
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className='overview-rtl'>
                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <div className='contact-box d-none d-xl-block'>
                      <ContactDetails contactData={contactData} />
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </section >
  )
}

export default PlanData