import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from "gatsby"
import PropertySliderOffPlan from '../PropertySlider/PropertySliderOffPlan'
import { Container, Row, Col } from 'react-bootstrap'
import './FindProperty.scss'
import PropertyOne from "../../images/property-one.png"
import PropertyTwo from "../../images/property-two.png"
import PropertyThree from "../../images/property-three.png"
import PropertyFour from "../../images/property-four.png"
import Select from 'react-select'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { numberFormat } from '../../common/site/utils'

const OtherProperty = (props) => {


    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                offplans(limit: 10) {
                    display_address
                    developer
                    department
                    search_type
                    completion_date
                    bedroom
                    price
                    images
                    max_price
                    id
                    slug
                    extra
                   
                }
            }
        }
    `);

    const properties = data.glstrapi.offplans; 
    const [state, setState] = useState("property for sale")
    const tabbingData = {
        "property for sale": properties,
        "property for rent": properties
    }
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        className: (properties.length < 4) ? 'left-slider' : '',
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    let tabOptions = []
    Object.keys(tabbingData)?.forEach((item, index) => {
        var main_data = tabbingData[item]
        if (main_data.length > 0) {
            tabOptions.push({ value: item, label: item })
        }
    })


    return (
        <section className='find-property other-property'>
            <Container>
                <Row>
                    <Col>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <div className='property-wrapper'>
                                <h2>Other Projects</h2>
                                <Slider {...settings}>
                                    {
                                        properties.map((item, index) => {
                                            if (item) {
                                                let uriStr = "";
                                                let mysale = "";

                                                if (item.search_type === "sales" && (item.department === 'residential') || (item.department === 'new_developments')) {
                                                    uriStr = `off-plan-project-for-sale/`
                                                    mysale = `myResSaleProp`
                                                } else if (item.search_type === "lettings" && item.department === 'residential') {
                                                    uriStr = `property-to-rent/`
                                                    mysale = `myResRentProp`
                                                }
                                                return (item?.id === props?.id ? null : <PropertySliderOffPlan price={item.price && item.price != null ? numberFormat(item.price) : ''} title={item.title}
                                                    location={item.display_address} propertyImages={item.images} propertyDetailsLink={`/${uriStr}${item.slug}-${item.id}/`}
                                                    extra={item?.extra}
                                                    
                                                    />
                                                )
                                            }

                                        })
                                    }
                                </Slider>
                            </div>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OtherProperty