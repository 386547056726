import React, { useState, Fragment } from "react";
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { graphql } from "gatsby"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import 'bootstrap/scss/bootstrap.scss'
import "../scss/global.scss";
import "../scss/grunticon.scss"
import "slick-carousel/slick/slick.css";
import "../scss/custom.scss";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { toTitleCase } from "../components/property-search/utils"
// import BrandLogo from "../images/logo-black.svg"
import BrandLogo from "../images/arabian-estates-logo-black.svg"
import OffPlanPropertyBanner from "../components/PropertyBanner/OffPlanPropertyBanner"
import DetailsHead from "../components/SellPlanning/SellPlanning"
import PropertyContent from "../components/PropertyContent/PropertyContent"
import PlanData from "../components/PlanData/PlanData"
import OtherProperty from "../components/FindProperty/OtherProperty";
import { numberFormat } from "../common/site/utils";
import FixedButtons from "../components/FixedButtons/FixedButtons";

const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"

const PROPERTY_DETAILS = gql`
  query GetProperty($id: String!) {
    offplans(where: { id: $id, publish:true}) {
      id
      crm_id
      status
      search_type
      virtual_tour
      bedroom
      bathroom 
      department
      building
      developer
      completion_date
      address
      area
      title
      price
      price_qualifier
      display_address
      area
      description
      long_description
      crm_negotiator_id
      latitude
      longitude
      images
      imagetransforms
      extra
    }
  }
`


const OffPlanPropertyDetailsPage = (props) => {
    const capitalize = s => {
        if (typeof s !== "string") return ""
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    //
    const pageurl = typeof window !== "undefined" ? window.location.href : ""
    let myid_filt = pageurl.split("?")
    let mypageurl = myid_filt[0]
    let property_slug = mypageurl.split("-")
    let id = property_slug[property_slug.length - 1]
    id = id.replace("/", "")

    const {
        loading,
        error,
        data: property_details,
    } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id },
    })
    // console.log(property_details)
    var metatitle = ""
    var metadesc = ""
    var seo_image_url = ""


    if (loading)
        return (
            <section className={"loader-wrapper"}>
                <div id="loader-wrapper">
                    <div id="loader" class="new-loader">
                        <img
                            src={BrandLogo}
                            className="loader-logo"
                            alt="logo"
                        />
                    </div>
                </div>
            </section>
        )

        return (
        <div className="offplan-details-page">
            <Layout>

                {property_details &&
                    property_details.offplans &&
                    property_details.offplans.length > 0
                    ? property_details.offplans.map((data, i) => {
                        var search_action = "sale"
                        if (data.search_type == "lettings") {
                            search_action = "rent";
                        }
                        let building = Array.isArray(data.building) ? data.building[0] || "property" : data.building || "property";
                        metatitle = "Off plan" + toTitleCase(building) + " for " + search_action + " with " + data.bedroom + " bedrooms in " + data.display_address + " at AED " + numberFormat(data.price) + ""
                        metadesc = "Know the details of " + building.toLowerCase() + " for " + search_action + " with " + data.bedroom + " bedrooms in " + data.display_address + " at AED " + numberFormat(data.price) + ". Book a viewing to get assistance in finding the right " + building.toLowerCase() + " for you."

                        let processedImages = JSON.stringify({});
                        seo_image_url = data.images && data.images.length > 0 && data.images[0].url;
                        if (data?.imagetransforms?.images_Transforms) {
                            processedImages = data?.imagetransforms.images_Transforms;
                            var resized_images = JSON.parse(processedImages);
                            const SrcCftle = data.images && data.images.length > 0 && data.images[0].url.substring(data.images && data.images.length > 0 && data.images[0].url.indexOf(STAGE_ENV));
                            const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['440x288'] : data.images && data.images.length > 0 && data.images[0].url;
                            //setResizedImage(ProcessedUrl);              
                            seo_image_url = ProcessedUrl
                        }
                        // if (typeof document !== `undefined`) {
                        //     setTimeout(function () {
                        //         var pagehead = document.getElementsByTagName('head')[0];
                        //         var social_share_meta = '<title>' + metatitle + ' | Arabian Estates Properties</title> <meta name="description" content="' + metadesc + '"> <meta property="og:title" content="' + metatitle + '"> <meta property="og:description" content="' + metadesc + '"> <meta property="og:image" content="' + seo_image_url + '"> <meta name="twitter:title" content="' + metatitle + '"> <meta name="twitter:description" content="' + metadesc + '"> <meta name="twitter:image" content="' + seo_image_url + '">';
                        //         pagehead.innerHTML = social_share_meta + pagehead.innerHTML;
                        //     }, 450);
                        // }
                        return (
                            <div className="offplan-detail">
                                <Seo title={metatitle+" | Arabian Estates"} description={metadesc} image={seo_image_url ? seo_image_url : data.images.length > 0 ? data.images[0].url : null}/>
                                <h1 className="d-none">{metatitle}</h1>
                                <DetailsHead type="offplan"/>
                                <OffPlanPropertyBanner propertyData={data} />
                                {/* <PlanData /> */}
                                <PropertyContent {...data} offPlan={true} pageurl={pageurl} property_title={data.display_address} property_img={data?.images[0]?.url}/>
                                <OtherProperty id={data?.id} />
                                <FixedButtons pageurl={pageurl} property_title={data.display_address} property_img={data?.images[0]?.url}/>
                            </div>
                        )
                    }) : ''}

            </Layout>
        </div>
    )
}

export default OffPlanPropertyDetailsPage
